<template>
  <section class="why_section layout_padding">
<div class="container">
  <div class="heading_container heading_center">
    <h2>
      Varför Välja <span>Oss</span>
    </h2>
  </div>
  <div class="why_container">
    <div class="box">
      <div class="img-box">
        <img src="images/w1.png" alt="">
      </div>
      <div class="detail-box">
        <h5>
          Innovativ och Anpassad Lösning
        </h5>
        <p>
          Vår användning av den senaste AI-tekniken i webbutvecklingen innebär att vi erbjuder moderna, snabba och responsiva hemsidor som är skräddarsydda för att matcha dina unika behov och mål. Vi kombinerar estetik med funktionalitet för att skapa en optimal användarupplevelse och säkerställa att din webbplats inte bara ser bra ut, utan även presterar på topp.
        </p>
      </div>
    </div>
    <div class="box">
      <div class="img-box">
        <img src="images/w2.png" alt="">
      </div>
      <div class="detail-box">
        <h5>
          Helhetslösning för Online Framgång
        </h5>
        <p>
          Med våra tjänster inom SEO, hosting och kontinuerlig support, agerar vi som en one-stop-shop för din digitala närvaro. Från att öka din synlighet i sökmotorer till att erbjuda pålitlig och snabb hosting, samt ständig teknisk support, ser vi till att alla aspekter av din webbplats är optimerade för framgång.
        </p>
      </div>
    </div>
    <div class="box">
      <div class="img-box">
        <img src="images/w3.png" alt="">
      </div>
      <div class="detail-box">
        <h5>
          Långsiktig Partner i Digital Tillväxt
        </h5>
        <p>
          Vi ser varje kundrelation som ett långsiktigt partnerskap. Vår dedikation till kontinuerlig support och anpassning betyder att vi är med dig på varje steg i din digitala resa, alltid redo att svara på dina behov och hjälpa din webbplats att utvecklas och växa tillsammans med ditt företag.

Dessa punkter framhäver ert engagemang för att leverera högkvalitativa, anpassade lösningar och er vilja att vara en tillförlitlig partner för era kunders långsiktiga framgång.
        </p>
      </div>
    </div>
    <div class="box">
      <div class="img-box">
        <img src="images/w4.png" alt="">
      </div>
      <div class="detail-box">
        <h5>
          Nöjda kunder
        </h5>
        <p>
          Vår största stolthet och bästa rekommendation är det höga antalet nöjda kunder vi har hjälpt. Genom att lyssna på våra kunders behov och ständigt sträva efter att överträffa deras förväntningar, har vi byggt en solid grund av lojala kunder. Deras framgångshistorier och positiva feedback vittnar om kvaliteten på våra tjänster och vårt åtagande att leverera enastående resultat.
Denna tillagda punkt betonar vikten av kundnöjdhet och hur det speglar positivt på kvaliteten av era tjänster och ert engagemang för att leverera utmärkta resultat.
        </p>
      </div>
    </div>
  </div>
  <!-- <div class="btn-box">
    <a href="">
      Läs mer
    </a>
  </div> -->
</div>
</section>
</template>

<script>
export default {
  name: 'WhySection',
  props: {
    msg: String
  }
}
</script>

