<template>
  <section class="slider_section ">
  <div id="customCarousel1" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="container ">
          <div class="row">
            <div class="col-md-6 ">
              <div class="detail-box">
                <h1>
                  AI drivna hemsidor med senaste tekniken.
                </h1>
                <p>
                  Revolutionera Din Webbplats med AI-Driven Design från Easypress

I en värld där digital närvaro är avgörande, erbjuder vi på Easypress en unik kombination av estetik och effektivitet. Våra hemsidor är inte bara visuellt tilltalande, utan de är också byggda med den senaste tekniken och kraften av artificiell intelligens (AI), för att säkerställa optimal prestanda och användarupplevelse.

Snygg Design Möter Smart Teknik: Vår designfilosofi går ut på att skapa hemsidor som inte bara ser fantastiska ut, utan också fungerar felfritt. Vi använder AI för att skapa skräddarsydda layouter och designelement som speglar ditt varumärke och engagerar dina besökare på bästa möjliga sätt.

Snabbhet och Prestanda: Hastighet är nyckeln till en framgångsrik webbplats. Med AI-optimerade laddningstider och prestandaförbättringar säkerställer vi att din hemsida laddas blixtsnabbt och ger en smidig användarupplevelse, vilket är avgörande för att behålla besökare och förbättra sökmotorrankningar.
                </p>
                <div class="btn-box">
                  <a href="/create" class="btn1">
                    Skapa hemsida
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="img-box">
                <img src="images/slider-img.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item ">
        <div class="container ">
          <div class="row">
            <div class="col-md-6 ">
              <div class="detail-box">
                <h1>
                  Igång med din hemsida på under en vecka
                </h1>
                <p>
                  Effektivitet och Kvalitet: Trots den snabba tidsramen, kompromissar vi aldrig med kvaliteten. Vi använder de senaste verktygen och metoderna för att säkerställa att din webbplats är både modern och användarvänlig.

Klar för Lansering: Inom en vecka kommer vi att ha din webbplats klar för granskning, testning och lansering. Vi ser till att allt fungerar felfritt och att webbplatsen uppfyller alla dina förväntningar.

Stöd Efter Lansering: Efter att din webbplats är live, fortsätter vårt stöd. Vi finns här för att hantera eventuella frågor eller uppdateringar som behövs, och säkerställer att din webbplats fortsätter att fungera smidigt.

Låt oss ta ditt digitala närvaro till nästa nivå - snabbt och professionellt. Kontakta Easypress idag för att få din webbplats uppe och igång på bara en vecka!
                </p>
                <div class="btn-box">
                  <a href="/create" class="btn1">
                    Skapa hemsida
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="img-box">
                <img src="images/slider-img.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="container ">
          <div class="row">
            <div class="col-md-6 ">
              <div class="detail-box">
                <h1>
                  SEO, Google analytics <br>
                  Hosting
                </h1>
                <p>
                  Effektivisera Din Digitala Närvaro med Våra Webbtjänster

I den digitala världen är det essentiellt att inte bara vara online, utan att sticka ut. På Easypress erbjuder vi specialiserade tjänster för att förstärka din webbnärvaro.

SEO: Våra experter optimerar din webbplats för att förbättra synligheten i sökmotorer, vilket leder till ökad organisk trafik.

Hosting: Vi garanterar snabb och pålitlig hosting, vilket är kritiskt för både sökmotorsranking och användarupplevelse.

Responsiva Hemsidor: Våra hemsidor är designade för att vara visuellt tilltalande och fungera smidigt på alla enheter, vilket säkerställer en optimal användarupplevelse.

Kontakta oss för att uppgradera din digitala närvaro!
                </p>
                <div class="btn-box">
                  <a href="/create" class="btn1">
                    Skapa hemsida
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="img-box">
                <img src="images/slider-img.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ol class="carousel-indicators">
      <li data-target="#customCarousel1" data-slide-to="0" class="active"></li>
      <li data-target="#customCarousel1" data-slide-to="1"></li>
      <li data-target="#customCarousel1" data-slide-to="2"></li>
    </ol>
  </div>

</section>
</template>

<script>
export default {
  name: 'WhySection',
  props: {
    msg: String
  }
}
</script>

