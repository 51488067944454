<template>
  <header class="header_section">
  <div class="container-fluid">
    <nav class="navbar navbar-expand-lg custom_nav-container ">
      <a class="navbar-brand" href="/">
        <span>
          Easypress
        </span>
      </a>

      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class=""> </span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav  ">
          <li class="nav-item active">
            <a class="nav-link" href="/">Hem <span class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/create"> Skapa din hemsida</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#services">Tjänster</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#why">Varför</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#kontakt">Kontakt</a>
          </li>
          <!--
          <li class="nav-item">
            <a class="nav-link" href="#"> <i class="fa fa-user" aria-hidden="true"></i> Login</a>
          </li>
          <form class="form-inline">
            <button class="btn  my-2 my-sm-0 nav_search-btn" type="submit">
              <i class="fa fa-search" aria-hidden="true"></i>
            </button>
          </form>
        -->
        </ul>
      </div>
    </nav>
  </div>
</header>
</template>
<script>
export default {
  name: 'HeaderSection',
  props: {
    msg: String
  }
}
</script>
