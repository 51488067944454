<template>
    <!-- info section -->

<section class="info_section layout_padding2">
<div class="container">
  <div class="row">
    <div class="col-md-6 col-lg-3 info_col">
      <div class="info_contact">
        <h4>
          Address
        </h4>
        <div class="contact_link_box">
          <a href="">
            <i class="fa fa-map-marker" aria-hidden="true"></i>
            <span>
              Johan skyttesväg 203, Älvsjö
            </span>
          </a>
          <!-- <a href="">
            <i class="fa fa-phone" aria-hidden="true"></i>
            <span>
              Call +01 1234567890
            </span>
          </a> -->
          <a href="">
            <i class="fa fa-envelope" aria-hidden="true"></i>
            <span>
              info@easypress.se
            </span>
          </a>
        </div>
      </div>
      <!-- <div class="info_social">
        <a href="">
          <i class="fa fa-facebook" aria-hidden="true"></i>
        </a>
        <a href="">
          <i class="fa fa-twitter" aria-hidden="true"></i>
        </a>
        <a href="">
          <i class="fa fa-linkedin" aria-hidden="true"></i>
        </a>
        <a href="">
          <i class="fa fa-instagram" aria-hidden="true"></i>
        </a>
      </div> -->
    </div>
    <!-- <div class="col-md-6 col-lg-3 info_col">
      <div class="info_detail">
        <h4>
          Info
        </h4>
        <p>
          necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful
        </p>
      </div>
    </div> -->
    <div class="col-md-6 col-lg-2 mx-auto info_col">
      <div class="info_link_box">
        <h4>
          Links
        </h4>
        <div class="info_links">
          <a class="active" href="/">
            Hem
          </a>
          <a class="" href="#about">
            Om oss
          </a>
          <a class="" href="#services">
            Tjänster
          </a>
          <a class="" href="#why">
            Varför
          </a>
          <a class="" href="#kontakt">
            kontakt
          </a>
        </div>
      </div>
    </div>
    <!-- <div class="col-md-6 col-lg-3 info_col ">
      <h4>
        Subscribe
      </h4>
      <form action="#">
        <input type="text" placeholder="Enter email" />
        <button type="submit">
          Subscribe
        </button>
      </form>
    </div> -->
  </div>
</div>
</section>

<!-- end info section -->
</template>
