<template>
  
<section class="about_section layout_padding">
<div class="container  ">
  <div class="heading_container heading_center">
    <h2>
      Om <span>Oss</span>
    </h2>
    <p>
      
    </p>
  </div>
  <div class="row">
    <div class="col-md-6 ">
      <div class="img-box">
        <img src="images/about-img.png" alt="">
      </div>
    </div>
    <div class="col-md-6">
      <div class="detail-box">
        <h3>
          Välkommen till Easypress – Din Partner för Digital Framgång
        </h3>
        <p>
          

På Easypress, är vi passionerade om att skapa digitala lösningar som gör skillnad. Vårt team av experter är specialiserade på att leverera anpassade tjänster inom webbutveckling, hosting, SEO och kontinuerlig support för att säkerställa att din online närvaro är både kraftfull och effektiv.

Webbutveckling med AI: Vi använder den senaste AI-tekniken för att skapa snygga, snabba och responsiva webbplatser. Vår process säkerställer att din webbplats inte bara ser bra ut, utan även erbjuder en användarvänlig upplevelse som är optimerad för alla enheter.

Hosting: Vår hostingtjänst garanterar hög tillgänglighet, snabba laddningstider och oöverträffad säkerhet. Vi erbjuder skalbara lösningar för att möta dina behov - oavsett om din webbplats är stor eller liten.

SEO: Med vår SEO-expertis hjälper vi din webbplats att nå toppositioner i sökmotorerna. Vi fokuserar på att optimera din webbplats för att förbättra synlighet och driva relevant trafik.

Oavbruten Support: Vi förstår vikten av pålitlig support. Därför erbjuder vi omfattande assistans och vägledning så länge du är kund hos oss, för att säkerställa att din webbplats alltid fungerar optimalt.

På Easypress är vi dedikerade till att hjälpa våra kunder att lyckas i den digitala världen. Kontakta oss idag för att börja din resa mot en starkare online närvaro!


        </p>
        
        <!-- <a href="">
          Läs mer
        </a> -->
      </div>
    </div>
  </div>
</div>
</section>
</template>

<script>
export default {
  name: 'AboutSection',
  props: {
    msg: String
  }
}
</script>

