<template>
  <div class="hero_area">

<div class="hero_bg_box">
  <div class="bg_img_box">
    <img src="images/hero-bg.png" alt="">
  </div>
</div>

<!-- header section strats -->
<HeaderSection></HeaderSection>
<!-- end header section -->
<!-- slider section -->
<SliderSection></SliderSection>
<!-- end slider section -->
</div>


<!-- service section -->
<ServicesSection id="services"></ServicesSection>
<!-- end service section -->


<!-- about section -->
<AboutSection id="about"></AboutSection>
<!-- end about section -->

<!-- why section -->


<WhySection id="why"></WhySection>
<!-- end why section -->

<!-- team section -->
<TeamSection id="team"></TeamSection>
<!-- end team section -->

<ContactSection></ContactSection>
<!-- client section -->
<!-- <ClientSection></ClientSection> -->
<!-- end client section -->


<InfoSection></InfoSection>

<!-- footer section 
<section class="footer_section">
<div class="container">
  <p>
    &copy; <span id="displayYear"></span> All Rights Reserved By
    <a href="https://html.design/">Free Html Templates</a>
  </p>
</div>
</section>
-->
<!-- footer section -->

</template>

<!-- <script>
export default {
  name: 'HomePage',
  props: {
    msg: String
  }
}
</script> -->
<script>
import axios from 'axios';
import InfoSection from './InfoSection'
import HeaderSection from './HeaderSection'
import TeamSection from './TeamSection'
import WhySection from './WhySection'
import AboutSection from './AboutSection'
import ServicesSection from './ServicesSection'
import SliderSection from './SliderSection'
//import ClientSection from './ClientSection'
import ContactSection from './ContactSection'
export default {
  data() {
    return {
      pageId:12,
      pageContent: null
    };
  },
  components: {
    InfoSection,
    HeaderSection,
    TeamSection,
    WhySection,
    AboutSection,
    ServicesSection,
    SliderSection,
    ContactSection
    //ClientSection
  },
  mounted() {
    axios.post('https://easypress.se/graphql', {
      query: `
        query {
          pageBy(pageId: ${this.pageId}) {
            title
            content
          }
        }
      `
    })
    .then(response => {
      this.pageContent = response.data.data.pageBy;
      console.log(this.pageContent);
    })
    .catch(error => {
      console.error(error);
    });
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->

