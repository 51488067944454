import { createApp } from 'vue'
import App from './App.vue'
import '@popperjs/core'
import 'bootstrap'
import { inject } from '@vercel/analytics';
inject();
import reportWebVitals from './reportWebVitals';
import { sendToVercelAnalytics } from './vitals';
createApp(App).mount('#app')
reportWebVitals(sendToVercelAnalytics);
