<template>
  <NewDesign msg="Easypress.se"/>
</template>

<script>
import NewDesign from './components/NewDesign.vue'

export default {
  name: 'App',
  components: {
    NewDesign
  }
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* color: #2c3e50; */
  /* margin-top: 60px; */
}
.header{
  background-color:#2c3e50;
}
@import '~bootstrap/dist/css/bootstrap.css';
</style>
