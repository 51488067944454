<template>
  <div class="container-fluid">
    <div class="row justify-content-center py-4">
      <div class="col-md-6">
        <figure class="w-100 h-100">

        </figure>
      </div>
      <div class="col-md-6">
        <h1>Kontakta oss</h1>
  <form action="post" id="kontakt">
    <div class="mb-3">
      <label for="name" class="form-label">Namn</label>
      <input type="text" class="form-control" id="name" name="name" v-model="name" required>
    </div>
    <div class="mb-3">
      <label for="email" class="form-label">E-postadress</label>
      <input type="email" class="form-control" id="email" name="email" v-model="email" required>
    </div>
    <div class="mb-3">
      <label for="message" class="form-label">Meddelande</label>
      <textarea class="form-control" id="message" name="message" rows="4" v-model="message" required></textarea>
    </div>
    <button type="button" v-on:click="SendMessage" class="btn btn-primary">Skicka</button>
  </form>
  <p v-if="messageSent">
{{ messageSent }}
  </p>
      </div>
      
    </div>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  
  name: 'ContactSection',
  data() {
    return {
      name: '', // Initialize with empty values
      email: '',
      message: '',
      messageSent: '', // For displaying a confirmation message
    };
  },
  props: {
    msg: String
  },
  methods:{
    SendMessage(){
      let self = this;
      // eslint-disable-next-line
      grecaptcha.ready(function() {
        // eslint-disable-next-line
              grecaptcha.execute('6LdQDJYnAAAAAFaJalXELTSgcl034Nm7YCtOR8zo', {action: 'contactform'}).then(function(token) {
                console.log(token);
                  // Add your logic to submit to your backend server here.
                  axios.post("https://easypress.se/wp-json/contact-form-7/v1/contact-forms/180/feedback",{
                      "your-name":self.name,
                      "your-email":self.email,
                      "your-message":self.message,
                      "_wpcf7_recaptcha_response" : token,
                      "wpcf7_recaptcha_response" : token,
                      "recaptcha_response" : token,
                      "recaptcha" : token,
                      "token" : token
                  },{
                      headers: {
                          "Content-Type": "multipart/form-data",
                      }
                  })
                  .then(() => {
                    self.messageSent = "Meddelandet har skickats!";
                    self.name = "";
                    self.email = "";
                    self.message = "";
                  }).catch(function(){
                    self.messageSent.value = "Något gick fel, försök igen senare"
                  });
              });
            });
    }
  }
}
</script>
<style lang="scss" scoped>


figure{
  background-size: cover !important;
  background-position: center;
  background:url(https://easypress.se/wp-content/uploads/2023/09/hacking-2903156.jpg);
}
</style>