<template>
  <section class="team_section layout_padding">
<div class="container-fluid">
  <div class="heading_container heading_center">
    <h2 class="">
      Vårt <span> Team</span>
    </h2>
  </div>

  <div class="team_container">
    <div class="row justify-content-center d-flex">
      <div class="col-lg-3 col-sm-6">
        <div class="box ">
          <div class="img-box">
            <img src="images/team-2.jpg" class="img1" alt="">
          </div>
          <div class="detail-box">
            <h5>
              Eimy Rojas Veliz
            </h5>
            <p>
              Marketing Head
            </p>
          </div>
          <div class="social_box">
            <a href="#">
              <i class="fa fa-facebook" aria-hidden="true"></i>
            </a>
            <a href="#">
              <i class="fa fa-twitter" aria-hidden="true"></i>
            </a>
            <a href="#">
              <i class="fa fa-linkedin" aria-hidden="true"></i>
            </a>
            <a href="#">
              <i class="fa fa-instagram" aria-hidden="true"></i>
            </a>
            <a href="#">
              <i class="fa fa-youtube-play" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6">
        <div class="box ">
          <div class="img-box">
            <img src="images/team-1.jpg" class="img1" alt="">
          </div>
          <div class="detail-box">
            <h5>
              Walter Benavides
            </h5>
            <p>
              Financial advisor
            </p>
          </div>
          <div class="social_box">
            <a href="#">
              <i class="fa fa-facebook" aria-hidden="true"></i>
            </a>
            <a href="#">
              <i class="fa fa-twitter" aria-hidden="true"></i>
            </a>
            <a href="#">
              <i class="fa fa-linkedin" aria-hidden="true"></i>
            </a>
            <a href="#">
              <i class="fa fa-instagram" aria-hidden="true"></i>
            </a>
            <a href="#">
              <i class="fa fa-youtube-play" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6">
        <div class="box ">
          <div class="img-box">
            <img src="images/team-3.jpg" class="img1" alt="">
          </div>
          <div class="detail-box">
            <h5>
              Earl Martinez
            </h5>
            <p>
              Lead developer
            </p>
          </div>
          <div class="social_box">
            <a href="#">
              <i class="fa fa-facebook" aria-hidden="true"></i>
            </a>
            <a href="#">
              <i class="fa fa-twitter" aria-hidden="true"></i>
            </a>
            <a href="#">
              <i class="fa fa-linkedin" aria-hidden="true"></i>
            </a>
            <a href="#">
              <i class="fa fa-instagram" aria-hidden="true"></i>
            </a>
            <a href="#">
              <i class="fa fa-youtube-play" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-3 col-sm-6">
        <div class="box ">
          <div class="img-box">
            <img src="images/team-4.jpg" class="img1" alt="">
          </div>
          <div class="detail-box">
            <h5>
              Josephine Allard
            </h5>
            <p>
              Marketing Head
            </p>
          </div>
          <div class="social_box">
            <a href="#">
              <i class="fa fa-facebook" aria-hidden="true"></i>
            </a>
            <a href="#">
              <i class="fa fa-twitter" aria-hidden="true"></i>
            </a>
            <a href="#">
              <i class="fa fa-linkedin" aria-hidden="true"></i>
            </a>
            <a href="#">
              <i class="fa fa-instagram" aria-hidden="true"></i>
            </a>
            <a href="#">
              <i class="fa fa-youtube-play" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>
</section>
</template>
<script>
export default {
  name: 'TeamSection',
  props: {
    msg: String
  }
}
</script>
