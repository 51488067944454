<template>
  
  <section class="service_section layout_padding">
<div class="service_container">
  <div class="container ">
    <div class="heading_container heading_center">
      <h2>
        Våra <span>Tjänster</span>
      </h2>
      <p>
        
      </p>
    </div>
    <div class="row">
      <div class="col-md-4 ">
        <div class="box ">
          <div class="img-box">
            <img src="images/s3.png" alt="">
          </div>
          <div class="detail-box">
            <h5>
              Hosting
            </h5>
            <p>
              Snabb, Säker, Skalbar Hosting med Easypress: Upplev oavbruten tillgänglighet och blixtsnabba laddningstider med våra anpassade hostinglösningar. Skydda din webbplats och väx din närvaro online med oss!
            </p>
            <!-- <a href="">
              Läs mer
            </a> -->
          </div>
        </div>
      </div>
      <div class="col-md-4 ">
        <div class="box ">
          <div class="img-box">
            <img src="images/s2.png" alt="">
          </div>
          <div class="detail-box">
            <h5>
              Support
            </h5>
            <p>
              Obegränsad Support för Din Webbplats med Easypress: Som kund hos oss får du kontinuerlig, personlig support för din hemsida. Vi är här för att hjälpa dig, så länge du är kund hos oss, med allt från tekniska frågor till uppdateringar.
            </p>
            <!-- <a href="">
              Läs mer
            </a> -->
          </div>
        </div>
      </div>
      <div class="col-md-4 ">
        <div class="box ">
          <div class="img-box">
            <img src="images/s3.png" alt="">
          </div>
          <div class="detail-box">
            <h5>
              SEO
            </h5>
            <p>
              Förbättra Din Sökbarhet med Easypress: Vår expertis inom SEO hjälper din webbplats att klättra i sökmotorrankningarna. Låt oss maximera din online synlighet och driva mer trafik till din sida.
            </p>
            <!-- <a href="">
              Läs mer
            </a> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="btn-box">
      <a href="">
        Se alla
      </a>
    </div> -->
  </div>
</div>
</section>
</template>

<script>
export default {
  name: 'AboutSection',
  props: {
    msg: String
  }
}
</script>

